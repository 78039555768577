import { Grid } from "@material-ui/core";
import { useAPIUtils } from "@thinktuminc/core-ui-shared-react";
import { AccessUrlAPI } from "./AccessUrl.api";
import React from 'react'
import { useParams } from "react-router";
import { Config, SignatureUIContext } from "./Config";
import { BrowserLogger } from "@thinktuminc/browser-logging";
import thankyou from '../src/thankyou.jpg';

const logger = new BrowserLogger("SignaturePage");

export default function Signature(){
    const { publicUrlId } = useParams<{publicUrlId: string}>();
    const config = React.useContext(SignatureUIContext.ReactContext);
    const urlApi = new AccessUrlAPI(useAPIUtils(), config as Config )
    const [signNowurl, setSigningUrl] = React.useState<string>("");
    const [ messageListener, setMessageListener ] = React.useState<(event:MessageEvent<string>) => void>()
    const [ isSigned, setPageSigned] = React.useState<boolean>();

    const loadSignNowPage = async () =>{
        const signNow =  await urlApi.getPrivateUrl(publicUrlId) 
        setSigningUrl( signNow)
    }   
    React.useEffect(()=>{
        loadSignNowPage();
    },[config])

    
    React.useEffect(() => {
        if(messageListener) {
            logger.info("Registering for signnow message");
            window.addEventListener("message", messageListener);
        }
        
        return () => {
            if(messageListener){
                logger.info("Removing signnow message listener");
                window.removeEventListener("message", messageListener);
            }
        }
    }, [ messageListener ]);

    React.useEffect(() => {
        setMessageListener( () => (event:MessageEvent<any>) => {
            logger.info("Received message", event);
            if (event.data === "signnow_user_signed") {
                logger.debug("Show thank you")
                setPageSigned(true)
            }

            if(event.data.event === "change-route" && event.data.title === "Sign Now" && event.data.path.includes("thank-you")) {
                setPageSigned(true)
            }
          });
    }, [])

    if(isSigned){
        return (
            <div >
                <img src={thankyou} alt="Thank You" style={{marginLeft: '30%', marginTop: '5%'}}></img>
            </div>
        )
    }
    else{
        return (
            <>
            <Grid className="signature-class">
                <iframe src={signNowurl} title="Signature Page" style={{ boxSizing: "border-box", border: "none", width: "100%", height: "100vh" }}></iframe>
            </Grid>
            </>
        )
    
    }
}