import { APIUtils } from "@thinktuminc/core-ui-shared-react";
import { Config } from "./Config";

export class AccessUrlAPI {
    private readonly apiUtils:APIUtils
    private readonly config:Config
    constructor(apiUtils:APIUtils, config:Config ){
        this.apiUtils = apiUtils
        this.config = config
    }
    async getPrivateUrl(publicUrlId:string):Promise<string>{
        const url = `${this.config?.apiUrl}/signing-url?publicUrlId=${publicUrlId}`
        return await this.apiUtils.get(url, {})
    }
} 