import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './index.css';
import Signature from './SignaturePage'
import reportWebVitals from './reportWebVitals';
import { SignatureUIContext } from '../src/Config';

ReactDOM.render(
  <React.StrictMode>
      <SignatureUIContext.Provider>
        <Router>
        <Route path="/signature/:publicUrlId">
          <Signature/>
        </Route>
      </Router>
      </SignatureUIContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
