import { ConfigurationContext } from '@thinktuminc/core-ui-shared-react';
import { fromMetaTag } from '@thinktuminc/browser-configuration';

export class Config {
    constructor(values: Partial<Config>){
        console.log("Creating module config");
        Object.assign(this, values);
    }
    public apiUrl!: string;
}

export const SignatureUIContext = new ConfigurationContext(Config, {
    "apiUrl": {
        retriever: fromMetaTag("apiUrl"),
        ignorePattern: /\$\{.*?\}/,
        default: "http://localhost:18092"
    }
} );
